* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  touch-action: manipulation;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@font-face {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Pro'), url('./fonts/GothamPro.woff') format('woff');
}

@font-face {
  font-family: 'GothamPro-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Pro Medium'), url('./fonts/GothamPro-Medium.woff') format('woff');
}

@font-face {
  font-family: 'GothamPro-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Pro Bold'), url('./fonts/GothamPro-Bold.woff') format('woff');
}


@keyframes shake-horizontal {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}




body {
  font-family: "GothamPro-Medium";
}

button {
  background-color: #fff;
  border-radius: 0;
  color: #111;
  outline: none;
  cursor: pointer;
}

input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

input::placeholder {
  color: #dddddd;
}

.preloader {
  width: 100px;
  margin: auto;
}

.preloader-s {
  width: 18px !important;
  display: block;
}

.preloader-center {
  margin: auto;
}


.page-title {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 30px 0;
  font-size: 30px;
  font-family: "GothamPro-Bold";
  color: #000;
}

.Dashboard,
.Table,
.Income,
.Cost,
.Chart,
.SpareParts,
.StorageIncome,
.StorageCost,
.StorageNewItem,
.Booking,
.BookingNewRecord,
.Book,
.Calculation {
  width: 100%;
  padding: 10px 10px 10px 150px;
}

.search-block {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.search-block .input-main {
  width: 100%;
  position: relative;
}

.search-block .input-main input {
  width: 100%;
  padding: 10px 25px 8px 10px;
  font-family: "GothamPro-Medium";
  font-size: 16px;
  border: 1px solid #00000030;
  border-radius: 0;
  background-color: #fff;
  line-height: 1.2;
}

.search-block .input-main input::placeholder {
  color: #00000070;
}

.search-block .input-main button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
}


/* App component */

.App {
  display: flex;
  min-height: 100vh;
}



/* Nav component  */

.Nav {
  width: 120px;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: #1d1d1d;
  z-index: 999;
}

.nav-logo {
  width: 80px;
  padding: 10px;
  margin: 10px auto;
}

.nav-logo img {
  width: 100%;
  user-select: none;
  -webkit-user-drag: none;
}

.nav-user {
  font-size: 16px;
  line-height: 16px;
  color: #ffffffd5;
  text-align: center;
  margin-bottom: 10px;
}

.nav-list {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: 10px;
  position: relative;
  height: 100%;
}

.nav-link {
  width: 100%;
  background-color: #ffe600;
  margin: 5px 0;
}

.nav-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  width: 100%;
}

.nav-link-signout {
  background-color: #fff;
  color: #ac0000;
  padding: 7px 0 5px;
  text-align: center;
  cursor: pointer;
  font-family: "GothamPro-Bold";
  font-size: 14px;
}

.nav-link-signout i {
  display: none;
}


.nav-link a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0 8px;
  color: #000;
  text-align: center;
  font-family: "GothamPro-Bold";
  text-decoration: none;
  font-size: 14px;
}

.nav-link div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0 8px;
  color: #000;
  text-align: center;
  font-family: "GothamPro-Bold";
  text-decoration: none;
  font-size: 14px;
}

.nav-link a i {
  font-size: 16px;
  color: #000;
  display: none;
}


.nav-extra {
  position: absolute;
  bottom: 60px;
  right: 0;
  padding: 20px 8px 8px;
  background-color: #555;
  width: calc(25% - 10px);
  min-width: 80px;
}

.nav-logout {
  margin-bottom: 40px !important;
}

.nav-logout i {
  color: #b50000 !important;
  font-size: 17px !important;
}

.nav-extra-user {
  text-align: center;
  font-size: 13px;
  color: #ffffff69;
  margin-bottom: 1px;
}


.nav-logout>div:nth-child(2) {
  padding: 5px;
  background-color: #ffffff34;
}

.nav-extra-link a,
.nav-extra-link span {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
}

.nav-extra-link a,
.nav-extra-link>div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-extra-link span {
  display: block;
  margin-top: 4px;
  font-family: "GothamPro";
  font-size: 11px;
  text-align: center;
}

.nav-extra-link i {
  color: #fff;
  font-size: 20px;
}

.nav-extra-link {
  margin-bottom: 25px;
}

.nav-extra-link:nth-last-child(1) {
  margin-bottom: 10px;
}



/* Dashboard component */

.Dashboard {
  min-height: 100vh;
}

.dashboard-main {
  display: flex;
  max-width: 1100px;
}

.current-tarrif {
  margin-bottom: 20px;
  padding: 5px 15px;
  background-color: #ffe600;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  position: relative;
}

.current-tarrif span {
  margin-left: 10px;
  font-family: "GothamPro-Bold";
  position: relative;
  top: -1px;
  font-size: 15px;
}


.dashboard-main .leftside,
.dashboard-main .rightside {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.table-statistics .show-all {
  padding: 10px 15px;
  background-color: #ffe600;
  margin-top: auto;
  width: fit-content;
  cursor: pointer;
}

.dashboard-statistics,
.table-statistics {
  display: flex;
  flex-direction: column;
  font-family: "GothamPro-Bold";
  font-size: 16px;
}

.dashboard-statistics-row,
.table-statistics-row {
  display: flex;
  width: fit-content;
  height: 35px;
}

.dashboard-statistics-row:nth-child(odd),
.table-statistics-row:nth-child(odd) {
  background-color: #eeeeee;
}

.dashboard-statistics-row-label,
.table-statistics-row-label {
  width: 180px;
  margin-right: 5px;

}

.dashboard-statistics-row-value,
.table-statistics-row-value {
  width: 120px;
}

.dashboard-statistics-row-value span,
.table-statistics-row-value span {
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.dashboard-statistics-row-label,
.dashboard-statistics-row-value,
.table-statistics-row-label,
.table-statistics-row-value {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.dashboard-actions-list {
  display: flex;
  justify-content: flex-end;
}

.dashboard-action {
  margin: 0 20px;
  background-color: #ffe600;
  border: none;
  transition: 50ms;
  overflow: hidden;
}

.dashboard-action:hover {
  background-color: #f0d800;
}

.dashboard-action a {
  display: inline-block;
  color: #000;
  font-size: 20px;
  font-family: "GothamPro-Bold";
  text-decoration: none;
  padding: 8px 20px;
}


/* Table component  */
.Table {
  min-height: 100vh;
}

.select-wrap {
  margin-left: 15px;
  border: 1px solid #bdbdbd;
  width: 120px;
  overflow: hidden;
  background: #fff url("./images/select_icon.png") no-repeat 90% 50%;
}

.select-wrap select {
  display: flex;
  font-family: "GothamPro-Bold";
  padding: 8px 15px 6px 7px;
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
  font-size: 16px;
}

.select-wrap select:focus {
  outline: none;
}

.table-topside {
  display: flex;
}

.table-statistics {
  margin: 22px 0 0 150px;
}

.table-filter {
  display: flex;
  flex-direction: column;
}

.filter-item {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}

.filter-item-label {
  font-family: "GothamPro-Bold";
  margin-bottom: 2px;
  font-size: 14px;
}

.filter-input-wrap {
  display: flex;
  align-items: center;
}

.filter-input-clear {
  margin-left: 5px;
}

.filter-input-clear i {
  font-size: 17px;
  color: #00000030;
  transition: 50ms;
  cursor: pointer;
}

.filter-input-clear i:hover {
  color: #00000080;
}

.filter-input {
  width: 150px;
}

.filter-input select {
  width: 100%;
  padding: 9px 10px 6px;
  font-family: "GothamPro-Medium";
  font-size: 16px;
  box-shadow: none;
  border: 1px solid #00000030;
  border-radius: 0;
  background-color: #fff;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
  line-height: 1.2
}


/* Grid component */
.Grid {
  margin-top: 30px;
}

.grid-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px !important;
}

.grid-row-header {
  background-color: #ffe600;
}

.grid-row-header .grid-cell-name {
  line-height: 1;
}

.grid-row {
  display: flex;
  min-height: 37px;
  transition: 200ms;
  border-bottom: 1px solid #e9e9e9;
  min-width: fit-content;
}

.grid-row-delated {
  color: #00000025;
  position: relative;
}


.grid-row:nth-child(even) .grid-cell {
  background-color: #eeeeee;
}

.grid-row-card-paid .grid-cell {
  background-color: #b8f3b3 !important;
}

.grid-row .grid-cell:nth-last-child(1) {
  border-right: none;
}

.grid-cell {
  padding: 10px 10px;
  flex: 1;
  overflow: hidden;
}

.grid-cell-date {
  cursor: pointer;
  color: #000;
  transition: 100ms;
  transform-origin: center center;
  min-width: 130px;
  max-width: 130px;
}

.grid-cell-date>div {
  display: flex;
  justify-content: space-between;
}

.grid-cell-date span {
  margin-left: 5px;
  pointer-events: none;
}

.grid-cell-date:hover {
  font-family: "GothamPro-Bold";
}

.grid-cell-textcetner {
  justify-content: center;
}

.grid-cell-descr {
  flex: 2
}

.grid-cell-amount {
  max-width: 145px !important;
}

.grid-cell-actions {
  min-width: 115px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.grid-cell-actions i {
  color: #00000030;
  transition: color 50ms;
  cursor: pointer;
  font-size: 15px;
  z-index: 2;
}

.grid-cell-actions i:hover {
  color: #00000080;
}

.cell-action-quantity {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  background-color: #cccccc;
  border-radius: 4px;
  margin: 0 5px;
  text-decoration: none;
}

.cell-action-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.25;
}

.cell-action-quantity:hover {
  background-color: #bbbbbb;
}


.cell-action-quantity i {
  color: #00000080;
}


.delete-confirm {
  height: 100%;
  display: flex;
  padding: 0 10px;
  align-items: center;
  font-size: 13px;
  color: #fff;
  background-color: #d31717;
  font-family: "GothamPro-Bold";
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 100ms ease-in;
  opacity: 1;
  z-index: 1;
}

.delete-confirm-hidden {
  transition: 200ms;
  right: -100px !important;
  opacity: 0;
}




/* Income component */

.income-form,
.cost-form {
  width: 600px;
  margin: auto;
  padding: 12px;
  background-color: #fafafa;
  box-shadow: 0 0 0 2px #00000030;
}

form .form-row:nth-last-child(1) {
  margin: 0 auto !important;
}


.form-row {
  display: flex;
  align-items: center;
  margin: 0 auto 12px;
  width: fit-content;

}

.form-row-top {
  align-items: flex-start;
}

.form-row-label {
  text-align: end;
  width: 150px;
  margin-right: 20px;
  font-size: 16px;
}

.form-row-required::after {
  content: '*';
  color: #ac0000;
  font-weight: 600;
  margin-left: 1px;
}

.form-row-input {
  width: 200px;
  min-height: 36px;
  display: flex;
  flex-direction: column;
}

.input-checkbox {
  font-family: "GothamPro-Medium";
  font-size: 16px;
  border: 1px solid #00000030;
  line-height: 1.2;
  width: 36px;
  height: 36px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-checkbox i {
  font-size: 17px;
  color: #00000013;
}

.input-checked {
  background-color: #cdffc8;
}

.input-checkbox .checkbox-checked {
  color: #000;
}

.form-storageitem-img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin-top: 10px;
}

.input-date .form-control,
.form-row-input input {
  padding: 9px 10px 6px;
  font-family: "GothamPro-Medium";
  font-size: 16px;
  width: 100%;
  border: 1px solid #00000030;
  line-height: 1.2;
}

.form-row-input select {
  width: 100%;
  padding: 9px 10px 6px;
  font-family: "GothamPro-Medium";
  font-size: 16px;
  box-shadow: none;
  border: 1px solid #00000030;
  border-radius: 0;
  background-color: #fff;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
  line-height: 1.2
}

.form-row-input input:disabled {
  color: #747474;
}

.add-record {
  display: block;
  margin: 20px auto 0;
  background-color: #ffe600;
  border: none;
  transition: 50ms;
  overflow: hidden;
  color: #000;
  font-size: 20px;
  font-family: "GothamPro-Bold";
  padding: 10px 20px;
  transition: 50ms;
}

.add-record:disabled {
  background-color: #a5a5a5;
  opacity: 0.5;
  cursor: not-allowed;
}

.add-record:disabled:hover {
  background-color: #a5a5a5;
}


.add-record:hover {
  background-color: #f0d800;
}

.form-success,
.form-wrong {
  padding: 10px 40px;
  display: block;
  margin: 30px auto 0;
  width: fit-content;
  color: #000;
}

.form-success {
  background-color: #bcffb6;
  border: 2px solid #95ff8c;
}

.form-wrong {
  background-color: #ffb6b6;
  border: 2px solid #ff8c8c;
}


/* LoginPage component */

.LoginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-box {
  padding: 25px;
  background-color: #eeeeee;
}

.login-form-row {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.login-form-row img {
  width: 100px;
  margin: 0 auto 30px;
  user-select: none;
  -webkit-user-drag: none;
}

.row-label {
  font-size: 14px;
  margin-bottom: 2px;
  font-family: "GothamPro-Bold";
}

.row-input {
  width: 100%;
  padding: 10px 20px 8px;
  font-size: 18px;
  font-family: "GothamPro-Medium";
  border: 2px solid #00000030;
  outline: none;
}

.row-input-wrong {
  border: 2px solid #d40101;
  -webkit-animation: shake-horizontal 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: shake-horizontal 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

.login-form button {
  margin-top: 40px;
  width: 100%;
  padding: 10px 20px 8px;
  font-size: 18px;
  font-family: "GothamPro-Bold";
  outline: none;
  background-color: #ffe600;
  border: none;
  cursor: pointer;
}


/* chart page */


.recharts-wrapper {
  margin: 40px 0;
  position: relative;
  left: -23px;
}


.chart-wrap tspan {
  font-size: 15px;
}


/* spare parts page */

.SparePartItem {
  border: 1px solid transparent;
  border-bottom: 1px solid #dddddd;
}

.SparePartItem-open {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.SparePartItem>.grid-row {
  max-height: none;
  border: none;
  align-items: center;
}

.spare-parts-grid .grid-cell-actions {
  max-width: 200px;
  min-width: 150px;
}

.spare-parts-grid .grid-cell-servedby {
  max-width: 150px;
  min-width: 100px;
}

.spare-parts-grid .grid-cell-amount {
  max-width: 120px;
  min-width: 90px;
}

.spare-parts-grid .grid-cell-descr {
  max-width: 500px;
  min-width: 300px;
}



.grid-row-wrap {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}

.grid-row-wrap:nth-child(even) .grid-cell {
  background-color: transparent;
}

.grid-info {
  display: flex;
  flex-direction: column;
}

.grid-info-row {
  display: flex;
  padding: 10px;
}

.grid-info-link {
  color: #000;
  font-size: 13px;
  text-decoration: none;
  padding: 5px 15px;
  background-color: #cccccc;
}

.grid-info-link i {
  font-size: 12px;
  margin-right: 4px;
}

.grid-title {
  padding: 10px;
  color: #9c9c9c;
}

.grid-cell-name {
  min-width: 300px;
  line-height: 1.4;
}

.grid-cell-name span {
  color: #6e6e6e;
  margin-right: 5px;
  padding: 5px 3px 3px;
  background-color: #dbdbdb;
  border-radius: 2px;
}

.grid-cell-image {
  max-width: 120px;
  min-width: 120px;
}

.grid-cell-image img {
  height: 100px;
  width: 100%;
  object-fit: contain;
}

.grid-extra {
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;
  padding: 5px 0 20px 120px;
}

.grid-row-extra {
  border-bottom: 1px solid #d6d4d4;
}

.grid-row-extra-head {
  color: #9c9c9c;
  margin-top: 15px;
}

.grid-row-extra-head .grid-cell {
  color: #9c9c9c;
  font-size: 15px;
}

.grid-row-extra .grid-cell {
  background-color: transparent !important;
}

.grid-cell-negative {
  color: #d40101;
}

.grid-cell-positive {
  color: #0c9200;
}

.cell-action-toggle {
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px;
}

.cell-action-toggle i {
  font-size: 20px;
}

.grid-cell-actions i {
  position: relative;
}

.grid-cell-actions i.fa-sort-down {
  top: -3px;
}

.grid-cell-actions i.fa-sort-up {
  top: 3px;
}


.grid-item-row {
  display: flex;
}

.grid-row-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.grid-column-cell {
  display: flex;
  align-items: center;
}

.grid-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.grid-name span {
  padding: 5px 10px 3px;
  margin-bottom: 8px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  width: fit-content;
}

.grid-column-cell .grid-quantity {
  padding: 5px 7px 3px;
  background-color: #ffe600;
}

.grid-extra .grid-item-row {
  font-size: 14px;
}

.grid-item-date {
  padding: 7px 3px;
  min-width: 85px;
}

.grid-item-amount {
  padding: 7px 3px;
  min-width: 30px;
  display: flex;
  justify-content: center;
}

.grid-item-served_by {
  padding: 7px 3px;
  min-width: 65px;
  max-width: 65px;
  overflow: hidden;
}

.grid-item-description {
  padding: 7px 3px;
  word-break: break-all;
}






/* new storage item */

.page-actions {
  display: flex;
  justify-content: flex-end;
  max-width: 1100px;
}

.page-actions a {
  display: inline-block;
  color: #000;
  font-size: 20px;
  font-family: "GothamPro-Bold";
  text-decoration: none;
  padding: 8px 20px;
  background-color: #ffe600;
}

.page-actions a:hover {
  background-color: #f0d800;
}



/* booking page */

.bookings-wrap {
  margin-top: 30px;
}


.bookings-day-title {
  padding: 8px 16px;
  background-color: #ffe600;
  margin-bottom: 20px;
  font-size: 18px;
  color: #000;
  font-family: "GothamPro-Bold";
  width: fit-content;
}

.bookings-passed .bookings-day-title {
  background-color: #0000001c;
}

.bookings-upcoming .bookings-day-title {
  background-color: #ffe600;
}

.bookings-list {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  max-width: 1100px
}

.booking-row {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  font-size: 16px;
  overflow: hidden;
  border-bottom: 2px solid #eee;
}


.booking-row-main {
  display: flex;
  align-items: center;
}


.booking-row-time {
  padding: 7px 6px 5px;
  background-color: #0000001c;
  width: 120px;
  text-align: center;
}

.booking-row-time span:nth-child(1) {
  margin: 0 2px;
}

.booking-row-cars {
  margin: 0 10px;
  width: 60px;
  text-align: center;
}

.booking-row-person {
  margin: 0 10px;
  min-width: 50px;
  max-width: 80px;
  flex: 1;
  word-break: break-all;
}

.booking-row-extra {
  margin: 2px 0 15px;
  min-width: 60px;
  flex: 1;
  word-break: break-all;
  font-size: 16px;
}

.booking-extra-title {
  margin-top: 10px;
  color: #00000040;
}

.booking-row-phone a {
  text-decoration: none;
  color: #0150e4;
  padding: 2px;
}

.bookings-passed {
  margin-top: 40px;
}

.booking-row-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  padding: 5px;
  background-color: #0000001c;
  width: 120px;
}

.booking-row-date div:nth-child(1) {
  margin-bottom: 5px;
}

.booking-row-date span {
  margin: 0 2px;
}

.booking-row-actions {
  padding: 5px;
  width: 60px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.booking-action {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  background-color: #cccccc;
  border-radius: 4px;
  margin: 0 5px;
  text-decoration: none;
  cursor: pointer;
}


.booking-action i {
  color: #00000080;
  transition: color 50ms;
  font-size: 15px;
  pointer-events: none;
}

.booking-action:hover {
  background-color: #bbbbbb;
}


.booking-action .delete-confirm {
  right: 50px;
}





.schedule {
  padding: 50px 0;
}

.schedule-title {
  font-size: 18px;
  font-family: "GothamPro-Bold";
  margin: 0 0 10px 0;
}

.schedule-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
}

.BookingCell {
  width: 120px;
  padding: 5px;
  position: relative;
}

.BookingCell-wrap {
  background-color: #e0e0e0;
  padding: 7px 20px 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.BookingCell-partly .BookingCell-wrap {
  background-color: #ffe600;
}

.BookingCell-full .BookingCell-wrap {
  background-color: #f75757;
}

.BookingCell-count {
  font-size: 12px;
  color: #00000075;
  margin-top: 3px;
}



.BookDetail {
  max-width: 1100px;
}

.backToBookings {
  margin-bottom: 15px;
  padding: 7px 15px 6px;
  background-color: #ffe600;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  position: relative;
  border: none;
  font-family: "GothamPro-Medium";
}


.bookDetails-list {
  display: flex;
  flex-direction: column;
}

.bookDetails-listItem {
  padding: 10px 5px;
  display: flex;
  font-size: 14px;
}

.bookDetails-main {
  display: flex;
}

.bookDetails-listItem:nth-child(odd) {
  background-color: #eeeeee;
}

.bookDetails-cars {
  width: 27px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffe600;
}

.bookDetails-person {
  padding: 5px;
  width: 130px;
  display: flex;
}

.bookDetails-phone {
  width: 130px;
  padding: 5px;
  display: flex;
  color: #0150e4;
  text-decoration: none;
}

.bookDetails-extra {
  color: #00000070;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.bookDetails-new {
  margin-top: 50px;
}



.rdtPicker {
  border: 1px solid #ccc !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .2) !important;
}

.Notification-wrap {
  position: fixed;
  top: -50px;
  left: calc(50% + 60px);
  transform: translateX(-50%);
  transition: 300ms;
}

.Notification-wrap-active {
  top: 10px;
}


.Notification {
  padding: 14px 30px 13px;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #00000030;
}

.Notification i {
  margin-right: 15px;
}

.Notification-success {
  background-color: #eeffec;
  border: 1px solid #48da3b;
}

.Notification-success i {
  color: #0c9200;
}

.Notification-error {
  background-color: #ffecec;
  border: 1px solid #da3b3b;
}

.Notification-error i {
  color: #920000;
}

.Notification-text {
  color: #000;
  font-size: 16px;
  white-space: nowrap;
}

.Calculation .grid-wrap {
  max-width: 800px !important;
  margin-top: 30px;
}

.Calculation .grid-cell-extra {
  color: #00000050;
  font-size: 13px;
  margin-left: 10px;
}

.Calculation .grid-cell-sallary {
  min-width: 200px;
  display: flex;
  align-items: center;
}

.Calculation .grid-cell-date {
  cursor: initial;
}

.Calculation .grid-cell-date:hover {
  font-family: inherit;
}



/* Workshifts page */

.workShifts {
  overflow: hidden;
  max-width: 500px;
}

.WorkShiftsCard {
  padding: 15px;
  background-color: #f7f7f7;
  margin: 0 0 15px;
  display: flex;
  flex-direction: column;
}

.WorkShiftsCard .card-title {
  display: flex;
  align-items: center;
}

.WorkShiftsCard .card-title span {
  padding: 5px 5px 3px 0;
  font-family: "GothamPro-Bold";
  margin-right: 10px;
  text-transform: uppercase;
}

.WorkShiftsCard .card-staff {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}

.WorkShiftsCard .card-staff-main {
  overflow: hidden;
}

.WorkShiftsCard .card-staff-row {
  display: flex;
  align-items: center;
  margin: 5px 0;
  position: relative;
}

.WorkShiftsCard .card-staff-row .card-staff-item {
  width: calc(100% - 67px);
  display: flex;
  position: relative;
  left: 15px;
}

.WorkShiftsCard .card-staff-person {
  padding: 7px 10px 5px;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  z-index: 7;
}

.WorkShiftsCard select {
  width: 80px;
  padding: 7px 10px 5px;
  font-family: "GothamPro-Medium";
  font-size: 16px;
  box-shadow: none;
  border: 1px solid #00000030;
  border-radius: 0;
  background-color: #fff;
  background-image: none;
  -webkit-appearance: none;
  cursor: pointer;
  margin-right: 4px;
  height: 32px;
}

.WorkShiftsCard input {
  padding: 7px 5px 5px;
  font-family: "GothamPro-Medium";
  font-size: 16px;
  width: 60px;
  border: 1px solid #00000030;
  margin: 0 3px;
  text-align: center;
  height: 32px;
}

.WorkShiftsCard .rdtPicker {
  transform: translateX(-25%);
  width: 130px !important;
}

.WorkShiftsCard .rdt.invalid input {
  border-color: #ff2222;
}

.WorkShiftsCard select.wrong-select {
  border-color: #ff2222;
}

.WorkShiftsCard .card-staff-action {
  width: 30px;
  height: 30px;
  display: flex;
  background-color: #dfdfdf;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 7px;
  text-decoration: none;
  margin-left: auto;
  cursor: pointer;

}

.WorkShiftsCard .card-staff-action.card-staff-action-alone {
  margin-top: 5px;
  width: 50px;
  margin-left: 0;
}

.WorkShiftsCard .card-staff-action.card-staff-action-wide {
  width: 40px;
}

.WorkShiftsCard .card-staff-action i {
  color: #00000080;
  font-size: 14px;
}

.WorkShiftsCard .remove-confirm {
  padding: 9px 5px 7px;
  background-color: #ff2222;
  color: #fff;
  position: absolute;
  right: -200px;
  top: 1px;
  transition: 100ms;
  font-size: 14px;
  z-index: 8;
  cursor: pointer;
}

.WorkShiftsCard .remove-confirm-active {
  right: 0 !important;
}

.WorkShiftsCard .card-no-staff {
  color: #ff1414;
}


.WorkShiftsCard .timeline {
  width: calc(100% - 37px);
  display: flex;
  justify-content: space-between;
  color: #00000070;
  font-size: 11px;
  border-top: 1px dashed #00000040;
  padding: 5px 0 10px;
  z-index: 5;
}

.WorkShiftsCard .timeline span {
  position: relative;
}

.WorkShiftsCard .timeline span::before {
  content: '';
  display: block;
  width: 1px;
  height: 500px;
  border-left: 1px dashed #00000040;
  transform: translateX(50%);
  position: absolute;
  bottom: 13px;
  left: 50%;
}


@media (max-width: 980px) {
  .SpareParts .Grid {
    overflow-x: scroll;
  }
}



@media (max-width: 768px) {

  .Nav {
    width: 60px;
  }

  .nav-list {
    height: calc(100% - (68.39px + 10px));
    margin-top: 10px;
  }

  .nav-user {
    font-size: 11px;
    word-break: break-all;
  }

  .nav-logo {
    padding: 5px;
    width: auto;
  }

  .nav-link {
    margin: 7px 0;
  }

  .nav-link a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-link a i,
  .nav-link-signout i {
    display: block;
    font-size: 20px;
  }

  .nav-link a span,
  .nav-link-signout span {
    display: none;
  }

  .dashboard-main {
    flex-direction: column;
  }

  .dashboard-statistics-row-label,
  .table-statistics-row-label {
    width: 60%;
  }

  .dashboard-statistics-row-value,
  .table-statistics-row-value {
    width: 40%;
  }

  .dashboard-statistics-row,
  .table-statistics-row {
    width: 100%;
  }

  .dashboard-statistics,
  .table-statistics {
    font-size: 14px;
  }

  .dashboard-statistics-row-label,
  .dashboard-statistics-row-value,
  .table-statistics-row-label,
  .table-statistics-row-value {
    padding: 0 5px;
  }

  .dashboard-actions-list {
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }

  .dashboard-action {
    width: 130px;
    margin: 15px 0;
  }

  .dashboard-action a {
    padding: 12px 20px;
    width: 100%;
    text-align: center;
  }

  .Grid {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .grid-wrap {
    min-width: fit-content;
  }

  .Dashboard,
  .Table,
  .Income,
  .Cost,
  .Chart,
  .SpareParts,
  .StorageIncome,
  .StorageCost,
  .StorageNewItem,
  .Booking,
  .BookingNewRecord,
  .Book,
  .Calculation {
    width: 100%;
    padding: 10px 10px 10px 70px;
  }

  .page-title {
    font-size: 20px;
    margin: 20px 0;
  }

  .table-topside {
    flex-direction: column;
  }

  .table-statistics {
    margin: 30px 0 0 0;
    max-width: 200px;
  }

  .income-form,
  .cost-form {
    width: 100%;
  }

  .form-row {
    flex-direction: column;
  }

  .form-row-label {
    margin: 0 0 10px 0;
    width: 100%;
    text-align: start;
  }

  .rdtPicker {
    width: 245px !important;
  }

  .chart-wrap {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .page-actions {
    justify-content: flex-start;
  }

  .bookDetails-listItem {
    flex-direction: column;
  }

  .bookDetails-extra {
    margin: 10px 0 0;
  }

  .Notification-wrap {
    left: calc(50% + 30px);
  }

}


@media (max-width: 425px) {

  .SparePartsGrid {
    overflow-x: hidden !important;
  }

  .SparePartItem {
    padding: 8px 0;
  }

  .Dashboard,
  .Table,
  .Income,
  .Cost,
  .Chart,
  .SpareParts,
  .StorageIncome,
  .StorageCost,
  .StorageNewItem,
  .Booking,
  .BookingNewRecord,
  .Book,
  .Calculation {
    padding: 10px 10px 60px;
  }

  .SparePartItem-open {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  .grid-cell {
    display: flex;
    padding: 10px;
  }

  .grid-cell-servedby {
    min-width: 80px;
  }

  .cell-action-add {
    margin-left: auto;
  }


  .grid-extra {
    padding: 5px 10px 30px;
  }

  .Nav {
    width: 100%;
    height: 60px;
    bottom: 0;
    background-color: #ffe600;
  }

  .nav-logo {
    display: none;
  }

  .nav-list {
    padding: 10px 5px;
    height: 100%;
    margin-top: 0;
    flex-direction: row;
  }

  .nav-bottom {
    display: none;
  }

  .nav-link {
    margin: 0 5px;
    background-color: transparent;
    flex: 1;
  }

  .nav-link a {
    flex-direction: column;
    padding: 0;
  }

  .nav-link div {
    flex-direction: column;
    padding: 0;
  }


  .nav-link a span,
  .nav-link-signout span,
  .nav-link>div span {
    display: block;
    margin-top: 4px;
    font-family: "GothamPro";
    font-size: 11px;
  }

  .nav-link i {
    font-size: 20px;
  }

  .bookings-wrap {
    margin-top: 50px;
  }

  .booking-row {
    font-size: 15px;
    overflow: hidden;
  }

  .bookings-day-title {
    width: initial;
    font-size: 17px;
  }

  .booking-row-cars {
    margin: 0 4px;
    width: 45px;
  }

  .booking-row-person {
    margin: 0 4px;
  }

  .booking-row-time {
    min-width: 100px;
    width: 100px;
    font-size: 14px;
    padding: 7px 4px 5px;
  }

  .booking-row-phone {
    max-width: initial;
    margin-top: 10px;
  }

  .booking-row-phone a {
    font-size: 14px;
    word-wrap: break-word;
  }

  .booking-row-date {
    min-width: 100px;
    width: 100px;
    font-size: 14px;
    padding: 7px 4px 5px;
  }

  .booking-row-extra {
    line-height: 1.2;
  }

  .BookingCell {
    width: calc(100% / 3);
    padding: 6px;
  }

  .rdtPicker {
    max-width: 300px;
    margin: auto;
  }

  .Notification-wrap {
    left: 50%;
  }

}


@media (max-width: 320px) {
  .booking-row-phone {
    max-width: initial;
  }

}